import React from "react";
import { graphql } from "gatsby";
import { NotFound, PageTemplateWrapper } from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import "../styles/main.less";

const NotFoundPage = ({ data }) => {
    const { copy, site } = data;
    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "consumer",
        locale: site.siteMetadata.lang,
        page: "cn-consumer-404"
    });
    return (
        <PageTemplateWrapper config={config}>
            <NotFound copyData={copy.data} />
        </PageTemplateWrapper>
    );
};

export default NotFoundPage;

export const pageQuery = graphql`
    query {
        copy: airtable(data: { title: { eq: "cn-copy" } }) {
            ...PagesCopyFragment
        }
        site {
            ...SiteFragment
        }
    }
`;
